import React from 'react'
import PropTypes from 'prop-types'
import {Link as RouteLink} from 'react-router-dom'
import {useIntl} from 'react-intl'
 
// Components
import {
    Breadcrumb as ChakraBreadcrumb,
    BreadcrumbItem as ChakraBreadcrumbItem,
    BreadcrumbLink as ChakraBreadcrumbLink,
    Box,
    // Hooks
    useStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
 
// Icons
import {ChevronRightGray} from '../icons'
 
// Others
import {categoryUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'
 
/**
* A simplification of the Chakra `Breadcrumb` component for our project needs. Given
* a list of categories, display a breadcrumb and it's items.
*/
const Breadcrumb = ({isCart, page, is_PDpage=false, is_PLP=false, categories, ...rest}) => {
    const intl = useIntl()
    const styles = useStyleConfig('Breadcrumb')

    return (
        <ChakraBreadcrumb
            className="sf-breadcrumb"
                {...styles.container}
            separator={<ChevronRightGray/>}
            {...rest}
        >
            {is_PDpage && !is_PLP &&
                <>
                    <ChakraBreadcrumbItem key={'home'} data-testid="sf-crumb-item">
                        <ChakraBreadcrumbLink
                            as={RouteLink}
                            to={'/'}
                        >
                            {intl.formatMessage({
                                id: 'cart.button.breadcrumbs.home',
                                defaultMessage: 'Home'
                            })}
                            
                        </ChakraBreadcrumbLink>
                    </ChakraBreadcrumbItem>
                    <ChevronRightGray/>
                    <ChakraBreadcrumbItem key={'pages'} data-testid="sf-crumb-item">
                        <ChakraBreadcrumbLink
                            as={RouteLink}
                            to={'/pages/' + page?.id}
                            className='last-entry'
                        >
                            {page?.name}
                        </ChakraBreadcrumbLink>
                    </ChakraBreadcrumbItem>
                </>
            }
            {is_PLP && <ChakraBreadcrumbItem key={'home'} data-testid="sf-crumb-item"><ChakraBreadcrumbLink
                        as={RouteLink}
                        to={'/'}
                    >
                        {intl.formatMessage({
                            id: 'cart.button.breadcrumbs.home',
                            defaultMessage: 'Home'
                        })}
                        
                </ChakraBreadcrumbLink></ChakraBreadcrumbItem>
            }
            {isCart && !is_PDpage && !is_PLP ? <><ChakraBreadcrumbItem key={'home'} data-testid="sf-crumb-item">
                    <ChakraBreadcrumbLink
                        as={RouteLink}
                        to={'/'}
                    >
                        {intl.formatMessage({
                            id: 'cart.button.breadcrumbs.home',
                            defaultMessage: 'Home'
                        })}
                        
                    </ChakraBreadcrumbLink>
                </ChakraBreadcrumbItem>
                <ChevronRightGray/>
                <ChakraBreadcrumbItem key={'shoppingCart'} data-testid="sf-crumb-item">
                    <ChakraBreadcrumbLink
                        as={RouteLink}
                        to={'/cart'}
                        className='last-entry'
                    >
                    {intl.formatMessage({
                        id: 'cart.button.breadcrumbs.shopping_cart',
                        defaultMessage: 'Shopping cart'
                    })}
                    </ChakraBreadcrumbLink>
                </ChakraBreadcrumbItem></> :
            
            !is_PDpage && is_PLP && categories?.map((category, index) => (
                <ChakraBreadcrumbItem key={category.id} data-index={index} data-testid="sf-crumb-item">
                    <ChakraBreadcrumbLink
                        as={RouteLink}
                        className={index + 1 === categories?.length ? 'last-entry' : ''}
                        to={categoryUrlBuilder(category, intl.locale)}
                        {...styles.link}
                    >
                        {category.name}
                    </ChakraBreadcrumbLink>
                </ChakraBreadcrumbItem>
            ))}
        </ChakraBreadcrumb>
    )
}
 
Breadcrumb.displayName = 'Breadcrumb'
 
Breadcrumb.propTypes = {
    /**
     * The categories to be displayed in this breadcrumb.
     */
    categories: PropTypes.array
}
 
export default Breadcrumb