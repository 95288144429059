import React from 'react'
import {useParams} from 'react-router-dom'
import {Box} from '@salesforce/retail-react-app/app/components/shared/ui'
import {usePage} from '@salesforce/commerce-sdk-react'
import {Page} from '@salesforce/commerce-sdk-react/components'

import {
    InteruptorTilesRow,
    ProductTilesRow,
    PromoSectionsRow,
    ProductListAndInteruptor
} from '../../page-designer/layouts'
import {
    RichText,
    ButtonComponent,
    PromoSection
} from '../../page-designer/assets'
import Breadcrumb from '../../../app/components/breadcrumb'
import {HTTPError, HTTPNotFound} from '@salesforce/pwa-kit-react-sdk/ssr/universal/errors'

const PAGEDESIGNER_TO_COMPONENT = {
    'commerce_assets.editorialRichText': RichText,
    'commerce_layouts.interuptorTiles': InteruptorTilesRow,
    'commerce_layouts.productTiles': ProductTilesRow,
    'commerce_assets.button': ButtonComponent,
    'commerce_layouts.promoSections': PromoSectionsRow,
    'commerce_assets.promoSection': PromoSection,
    'commerce_layouts.productListingAndInteruptor': ProductListAndInteruptor
}

const PageViewer = () => {
    const {pageId} = useParams()
    const {data: page, error} = usePage({parameters: {pageId}})
    if (error) {
        let ErrorClass = error.response?.status === 404 ? HTTPNotFound : HTTPError
        throw new ErrorClass(error.response?.statusText)
    }
    return (
        <Box layerStyle={'page'} className='pg-page'>
            <Breadcrumb 
                is_PDpage={true}
                page={page}
                className={page?.id + '-breadcrumbs'}
            />
            <Page page={page} components={PAGEDESIGNER_TO_COMPONENT} />
        </Box>
    )
}

PageViewer.displayName = 'PageViewer'

export default PageViewer